import { useEffect, useState } from "react";
import api from "../../../services/api/help-center-api";

import { useLogin } from "../../login/custom-hook/login-hook";

export const useHelpCenter = () => {
    const [apiResponse, setapiResponse] = useState([]);
    const [loading, setLoading] = useState(false);
    const { handleError } = useLogin();

    useEffect(() => {
        helpCenterAPI();
    }, []);

    const helpCenterAPI = async () => {
        setLoading(true);
        try {
            const response = await api.helpCenterdetail();
            // //console.log("Response", response);
            if (response && response.data && response.data.data && response.data.data.help_center) {
                setapiResponse(response.data.data);
                setLoading(false);
            } else {
                // //console.log("error occuered");
                setLoading(false);
            }
        } catch (err) {
            //console.log(err);
            if (err.response.status == 401) {
                handleError();
                setLoading(false);
            }
        }
    };

    return { apiResponse, loading };
};
