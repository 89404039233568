import { useContext } from "react";
import { DEFAULT_API_CONFIG } from "./api-config";
import axios from "axios";
import { UserContext } from "../../App";

const CreateAPI = async() => {
    // //console.log(DEFAULT_API_CONFIG.url);
    return axios.create({
        baseURL: DEFAULT_API_CONFIG.url,
        timeout: DEFAULT_API_CONFIG.timeout,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
};

const CreateAPI2 = async() => {
    let userToken = localStorage.getItem("token");
    let token = JSON.parse(userToken);

    return axios.create({
        baseURL: DEFAULT_API_CONFIG.url,
        timeout: DEFAULT_API_CONFIG.timeout,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: userToken && `Bearer ${token}`,
        },
    });
};

export async function callAPI(
    url,
    isGet,
    request = {},
    header = {},
    isPut,
    isDelete
) {
    // //console.log("payload", JSON.stringify(request));
    // //console.log("header", header);
    // //console.log("url", url)
    //   api = await CreateAPI();
    let api;
    isGet ? (api = await CreateAPI2()) : (api = await CreateAPI());

    let response = {};
    if (!isPut && !isDelete) {
        response = isGet ?
            await api.get(url, request) :
            await api.post(url, request, { headers: header });
        return response;
    } else if (isDelete) {
        response = api.delete(url, request, { headers: header });
    } else {
        response = api.put(url, request, { headers: header });
    }
    return response;
}