import "../../assets/css/style.css";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";
import { useHelpCenter } from "./custom-hook/help-hook";

const HelpCenter = () => {
    const { apiResponse, loading } = useHelpCenter();

    return (
        <>
            <section className="section home-page-section mv_main__sec">
                <div className="container-fluid px-0">
                    <div className="Page_content notification_page_ui pt-0">
                        <div className="container-fluid">
                            <div className="top_heading row my-0 pt-0">
                                <div className="col-md-3 my-4">
                                    <div className="Document_text">
                                        <h6>Help Center</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {loading && <Loader />}
                                <div className="col-12">
                                    {apiResponse?.help_center?.description ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: apiResponse.help_center.description,
                                            }}
                                        />
                                    ) : (
                                        <RecordNotFound heading="No Description found" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HelpCenter;
