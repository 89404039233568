import { callAPI } from "../config/api-core";
import {requestOTPAPIMethods} from "../methods/request-otp-api-method"

const requestOtpAPI = async (request) => {
  // //console.log("hiii")
  const response = await callAPI(
    requestOTPAPIMethods,
    false,
    request,
    {},
    false,
    false
  );
  return response;
};

export const requestOtp = (request) => requestOtpAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    requestOtp,
};
