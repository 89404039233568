import "../../assets/css/refernewpatient.css";
import React, { useState } from "react";
import Loader from "../../components/loader";
import ToastNotification from "../../components/toast-notification"; // Import ToastNotification component
import { callAPI } from "../../services/config/api-core";
const NewFeature = () => {
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState(""); // "success" or "error"



    const handleSubmit = async () => {
        if (!description.trim()) {
            setToastMessage("Please enter your idea.");
            setToastType("error");
            setShowToast(true);
            return;
        }
        setLoading(true);

        try {
            // Simulating an API call
            let userToken = localStorage.getItem("token");
            let token = JSON.parse(userToken);
            
            let header = {
                "content-type": "multipart/form-data",
                Authorization: userToken && `Bearer ${token}`,
            };
            const response = await callAPI(
                '/suggest-feature',
                false,
                { description },
                header,
                false,
                false
            );
            console.log(response, 'callAPI');
            setToastMessage("Feature suggestion submitted successfully!");
            setToastType("success");
            setShowToast(true);
            setDescription(""); 
        } catch (error) {
            console.error("Error submitting feature suggestion:", error);
            setToastMessage("Something went wrong. Please try again later.");
            setToastType("error");
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <section className="section mv_main__sec">
                <div className="container-fluid px-0">
                    {showToast && (
                        <ToastNotification
                            setShow={setShowToast}
                            show={showToast}
                            content={toastMessage}
                            type={toastType} // Pass "success" or "error" for styling
                        />
                    )}
                    <div className="Page_content new-patient-page mv__new-patient-page">
                        <div className="row mt-0 pt-0">
                            <div className="col-12">
                                <div className="row top-section pb-0">
                                    <div className="col-md-3 col-6">
                                        <div className="my_patients pb-3 pt-3">
                                            <h6 className="mb-0">Suggest a Feature</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div
                                        className="col-sm-12 col-lg-12 col-xl-6 col-xxl-6 m-auto card"
                                        style={{
                                            padding: "3%",
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                            borderRadius: "12px",
                                            backgroundColor: "#ffffff",
                                            border: "none",
                                        }}
                                    >
                                        <div className="my-3 row p-2">
                                            <div className="col-md-3 col-12">
                                                <label htmlFor="featureDescription" className="form-label">
                                                    <strong>Enter your idea</strong>
                                                </label>
                                            </div>
                                            <textarea
                                                id="featureDescription"
                                                className="form-control mv___form_control"
                                                style={{ minHeight: "300px", minWidth: "90%" }}
                                                placeholder="Enter Description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            />
                                            <p
                                                style={{
                                                    minWidth: "57%",
                                                    margin: "0 auto",
                                                    marginTop: "2%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                We're always working to improve the experience and your practice, so
                                                we'd love to hear what could be done better.
                                            </p>
                                        </div>
                                        <div className="col-md-12 text-center mt-1">
                                            <button
                                                type="button"
                                                className="btn btn-save"
                                                style={{ minWidth: "60%" }}
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? <Loader /> : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NewFeature;
