import { callAPI } from "../config/api-core";
import { helpCenterAPIMethods } from "../methods/help-center-api-method";
const helpCenterAPI = async () => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    helpCenterAPIMethods,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

export const helpCenterdetail = () => helpCenterAPI();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    helpCenterdetail,
};
