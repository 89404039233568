import { useEffect, useState, useRef } from "react";
import api from "../../../services/api/existing-patients-api";
import apis from "../../../services/api/procedure-api";
import apiss from "../../../services/api/refer-api";
import apisss from "../../../services/api/existing-patient-journey-list-api";
import docIcon from "../../../assets/img/doc-logo.png";
import pdfIcon from "../../../assets/img/pdf-img.png";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useExistingPatientPost = () => {
  const [existingPatients, setExistingPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientID, setSelectedPatientID] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [selectedPatientPhone, setSelectedPatientPhone] = useState("");
  const [selectedPatientSurgey, setSelectedPatientSurgery] = useState("");
  const [selectedPatientProcedure, setSelectedPatientProcedure] = useState();
  const [JourneyList, setJourneyList] = useState([]);

  const [procedures, setProcedures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [notes, setNotes] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [docUploadError, setDocUploadError] = useState("");

  const hasMounted = useRef(false);
  const { handleError } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasMounted.current) {
      // setLoading(true);
      existingPatientsAPI();
      // proceduresAPI();
      existingJourneyAPI();
      // setLoading(false);
      hasMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (selectedPatient?.id) {
      //console.log("selectedPatient", selectedPatient);
      setSelectedPatientID(selectedPatient.id);
      setSelectedPatientName(selectedPatient.patient_name);
      setSelectedPatientPhone(selectedPatient.phone_number);
      setSelectedPatientSurgery(selectedPatient.same_day_surgery);
      setSelectedPatientProcedure(selectedPatient.procedure_id);
    }
  }, [selectedPatient]);

  const existingPatientsAPI = async () => {
    setLoading(true);
    try {
      const response = await api.getExistingPatientsAPI();
      // //console.log("Response", response);
      if (response && response.data.data) {
        setExistingPatients(response.data.data);
        setSelectedPatient(response.data.data[0]);
      } else {
      }
    } catch (err) {
      //console.log(err);
      if (err.response.status == 401) {
        handleError();
        setLoading(false);
      }
    }
  };

  const proceduresAPI = async () => {
    try {
      const response = await apis.procedure();
      // //console.log("Response", response);
      if (response && response.data) {
        setProcedures(response.data.data.procedures_list);
      } else {
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const referNewPatientAPI = async (request) => {
    // //console.log("ravy", request);
    setLoading(true);
    try {
      const response = await apiss.referNewPatient(request);
      // //console.log("Response", response);
      if (response && response.data.status === 1) {
        // //console.log("sucess", response.data.message);
        setMsg(response.data.message);
        setShow(true);
        setPreviewUrls([]);
        setLoading(false);
        navigate("/mypatient");
      } else {
        // //console.log("error");
        setMsg(response.data.message);
        setShow(true);
        setPreviewUrls([]);
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleSelectedPatient = (patient) => {
    let newPatient = existingPatients.find(
      (item) => item.patient_name == patient
    );
    //console.log("newPatient", newPatient);
    setSelectedPatient(newPatient);
  };

  const handleFileSelect = (e, document) => {
    setDocUploadError("");
    const newFiles = [...document, ...e.target.files];
    if (newFiles.length > 15) {
      setDocUploadError("Only a maximum of 15 files are allowed.");
    } else {
      setDocuments(newFiles);

      const newPriviewUrls = [];
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        if (
          file.type !== "application/pdf" &&
          file.type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
          file.type != "application/msword"
        ) {
          const objectUrl = URL.createObjectURL(file);
          newPriviewUrls.push(objectUrl);
        } else if (
          file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type == "application/msword"
        ) {
          newPriviewUrls.push(docIcon);
        } else {
          newPriviewUrls.push(pdfIcon);
        }
      }
      setPreviewUrls(newPriviewUrls);
    }
  };

  const handleDelete = (index, document) => {
    const newFiles = [...document];
    newFiles.splice(index, 1);
    setDocuments(newFiles);
    // if (documents.length > 15) {
    //   setDocUploadError("Only a maximum of 15 files are allowed.");
    // } else {
    //   setDocUploadError("")
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
    // }
  };

  const existingJourneyAPI = async () => {
    setLoading(true);
    // //console.log("journey api is calling")
    // //console.log("request", request);
    try {
      // setPatientJourney([]);
      const response = await apisss.existingPatientJourneyList();
      //console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setJourneyList(response.data.data.journey_list);
      } else {
        // setLoading(false);
        // //console.log("journey error");
      }
      setLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };

  return {
    existingPatients,
    selectedPatient,
    handleSelectedPatient,
    selectedPatientName,
    selectedPatientPhone,
    selectedPatientSurgey,
    setSelectedPatientName,
    setSelectedPatientPhone,
    setSelectedPatientSurgery,
    selectedPatientProcedure,
    setSelectedPatientProcedure,
    procedures,
    loading,
    documents,
    setDocuments,
    notes,
    setNotes,
    handleFileSelect,
    handleDelete,
    previewUrls,
    referNewPatientAPI,
    show,
    msg,
    setShow,
    selectedPatientID,
    docUploadError,
    JourneyList,
  };
};
