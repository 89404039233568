import "../../assets/css/style.css";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";
import { useNotifications } from "./custom-hook/notifications-hook";

const Notification = () => {
  const { notifications, loading } = useNotifications();
  // //console.log("notifications", notifications)

  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase()[0];
    }
  }


  return (
    <>
      {/* <Pagenotfound /> */}
      <section className="section home-page-section mv_main__sec">
        <div className="container-fluid px-0">
          <div className="Page_content notification_page_ui pt-0">
            <div className="container-fluid">
              <div className="top_heading row my-0  pt-0">
                <div className="col-md-3 my-4">
                  <div className="Document_text">
                    <h6>Notifications</h6>
                  </div>
                </div>
              </div>
              <div className="row">
                {loading && <Loader/>}
                <div className="col-12">
                  {notifications.length > 0  ? (
                    <div className="row notification-row">
                      {notifications.map((item, index) => {
                        return (
                          <div className="col-12" key={index}>
                            <div className="inner-content">
                              <div className="dj">
                                <p>{getFirstLetters(item.text)}</p>
                              </div>
                              <div className="dr-james">
                                <h6 className="">
                                  <span className="highlight-text">
                                    {item.text}
                                  </span>
                                </h6>
                                <p className="last-seen">{item.created_at_modified}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {/* <div className="col-12">
                        <div className="inner-content">
                          <div className="dj background-blue">
                            <p>DJ</p>
                          </div>
                          <div className="dr-james">
                            <h6 className="">
                              <span className="highlight-text">
                                Dr. James Ward
                              </span>{" "}
                              changed{" "}
                              <span className="highlight-text">Jose’s</span>{" "}
                              journey status
                            </h6>
                            <p className="last-seen">10 mint ago</p>
                            <p className="discription">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distrib...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="inner-content">
                          <div className="dj background-green">
                            <p>DJ</p>
                          </div>
                          <div className="dr-james">
                            <h6 className="">
                              <span className="highlight-text">
                                Dr. James Ward
                              </span>{" "}
                              changed{" "}
                              <span className="highlight-text">Jose’s</span>{" "}
                              journey status
                            </h6>
                            <p className="last-seen">10 mint ago</p>
                            <p className="discription">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distrib...
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="inner-content">
                          <div className="dj background-dark-red">
                            <p>DJ</p>
                          </div>
                          <div className="dr-james">
                            <h6 className="">
                              <span className="highlight-text">
                                Dr. James Ward
                              </span>{" "}
                              changed{" "}
                              <span className="highlight-text">Jose’s</span>{" "}
                              journey status
                            </h6>
                            <p className="last-seen">10 mint ago</p>
                            <p className="discription">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum is that it has a more-or-less normal
                              distrib...
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <RecordNotFound heading="no notification found" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notification;
