import React, { useEffect } from "react";
import { Formik, Form as FormikFrom, ErrorMessage } from "formik";
import { validation } from "./validation";
import "../../assets/css/login.css";
import img1 from "../../assets/img/manson-logo.png";
import img2 from "../../assets/img/main-img.png";
import { useLogin } from "./custom-hook/login-hook";
import InputField from "../../components/inputField";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
// import {Link} from "react-router-dom"

const Login = () => {
  const { isRevaled, hideshow, loginUserAPI, loginError, loading } = useLogin();
  

  return (
    <section className="Login_section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            {loading && <Loader />}
            <div className="row main_row">
              <div className="col-md-6">
                <div className="left_side">
                  <div className="logo_card mb-4 ms-0">
                    <img src={img1} alt="..." />
                  </div>
                  <div className="left_text">
                    <h2>Login Your Account </h2>
                    <p>
                      Please enter your email address & password to access your
                      account.
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validation}
                    onSubmit={(values) => {
                      // //console.log(values);
                      loginUserAPI(values);
                    }}
                  >
                    {({ handleChange, handleBlur }) => (
                      <FormikFrom>
                        {loginError && (
                          <span
                            className="error"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            {loginError}
                          </span>
                        )}

                        <div className="mb-3">
                          <InputField
                            label="Email address*"
                            htmlFor="exampleInputEmail1"
                            labelClassName="form-label email_text"
                            type="email"
                            name="email"
                            inputClassName="form-control"
                            id="exampleInputEmail1"
                            ariaDescribedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {/* <label
                            htmlFor="exampleInputEmail1"
                            className="form-label email_text"
                          >
                            Email address*
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "13px" }}
                          >
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        <div className="mb-3 eyeicon">
                          <InputField
                            label="Password*"
                            htmlFor="exampleInputPassword1"
                            labelClassName="form-label password_text"
                            type={isRevaled ? "text" : "password"}
                            name="password"
                            inputClassName="form-control"
                            id="exampleInputPassword1"
                            ariaDescribedby="passwordHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {/* <label
                            htmlFor="exampleInputPassword1"
                            className="form-label password_text"
                          >
                            Password*
                          </label>
                          <input
                            type={isRevaled ? "text" : "password"}
                            name="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          /> */}
                          {isRevaled ? (
                            <i
                              className="fa fa-eye-slash mv__password_eye"
                              // style={{
                              //   fontSize: "17px",
                              //   color: "#33B0CE",
                              //   position: "absolute",
                              //   top: "40px",
                              //   right: "13px",
                              // }}
                              onClick={() => hideshow()}
                              
                            />
                            ) : (
                              <i
                                className="fa fa-eye mv__password_eye"
                                // style={{
                                //   fontSize: "17px",
                                //   color: "#33B0CE",
                                //   position: "absolute",
                                //   top: "40px",
                                //   right: "13px",
                                // }}
                                onClick={() => hideshow()}
                              />
                            )}
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "13px" }}
                          >
                            <ErrorMessage name="password" />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary Login_button"
                        >
                          Login
                        </button>
                        <div className="Forgot_password">
                          <Link to="/forgot-pwd">Forgot Password?</Link>
                        </div>
                      </FormikFrom>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="right_side">
                  <h2>Refer New Patients & Patient Journey </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="main_img">
                    <img src={img2} alt=".." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
