import { usePubNub } from "pubnub-react";
import { useEffect, useRef, useState } from "react";
import "../assets/css/my-patient-chat.css";
import api from "../services/api/chat-activity-api";

const MyPatientChat = ({ patientChannel, selectedPatient }) => {
  const pubnub = usePubNub();
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const handleMessage = (event) => {
    // //console.log("event", event);
    const message = event;
    // //console.log("mmmm", message)
    if (
      message.message.type == "patient_chat" &&
      message.message.chat_message
    ) {
      // if (message.message.chat_message) {
      setMessages((prevMsg) => [...prevMsg, message.message]);
      // }
    }
  };

  const fetchHistory = async () => {
    setMessages([]);
    try {
      const result = await pubnub.fetchMessages({
        channels: [patientChannel],
        // count: 100, // maximum number of messages to retrieve
      });
      const historyMessages = result.channels[patientChannel].map(
        (message) => message.message
      );
      setMessages(historyMessages);
    } catch (error) {
      console.error("Error fetching history: ", error);
    }
  };

  useEffect(() => {
    // document.getElementById("myList").innerHTML = "";
    pubnub.subscribe({ channels: patientChannel });
    fetchHistory();
    const listenerParams = { message: handleMessage };
    pubnub.addListener(listenerParams);
    return () => {
      pubnub.unsubscribe({ channels: patientChannel });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, patientChannel]);

  // useEffect(() => {
  //   //console.log("changed channel")
  //   pubnub.addListener({
  //     message: message => {
  //       //console.log("mmm", message.message.chat_message )
  //       const node = document.createElement("li");
  //       const textnode = document.createTextNode(message.message.chat_message);
  //       node.appendChild(textnode);
  //       document.getElementById("myList").appendChild(node);
  //     },
  //   });

  //   pubnub.subscribe({ channels: patientChannel , withPresence: true});

  //   return () => {
  //     pubnub.unsubscribe({ channels: patientChannel });
  //   };
  // }, [pubnub, patientChannel]);

  const sendMessage = async () => {
    if (messageInput.length > 0) {
      // const message = {
      //   text: messageInput,
      //   // timestamp: new Date().toISOString(),
      // };
      const message = {
        title: "Patient Chat",
        type: "patient_chat",
        chat_message: messageInput,
        user_id: selectedPatient.id,
      };
      pubnub.publish({ channel: patientChannel[0], message });
      let doctorId = JSON.parse(localStorage.getItem("user"));
      // //console.log("doctorId", doctorId.id);
      const request = {
        user_id: doctorId.id,
        sender_id: selectedPatient.id,
      };
      try {
        const response = await api.chatActivity(request);
        //console.log("Response", response);
        if (response && response.data.status === 1) {
        } else {
        }
      } catch (err) {
        //console.log(err);
      }
      setMessageInput("");
    }
  };

  const handleInput = (e) => setMessageInput(e.target.value);

  return (
    <div className="row">
      <div className="col-12">
        <div className="chat_box">
          <ul className="patient_pubnub_mgs" id="myList">
            {messages.map((message, index) => {
              //console.log("mes", message);
              return (
                <li
                  key={index}
                  className={
                    message.user_id == selectedPatient.id
                      ? "currentUser"
                      : "adminUser"
                  }
                >
                  {message.chat_message}
                </li>
              );
            })}
          </ul>
          <div className="input-group input-group-lg mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter message"
              onChange={handleInput}
              value={messageInput}
            />
            <button
              type="button"
              className="input-group-text send_btn"
              onClick={sendMessage}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyPatientChat;
