import { useEffect, useState } from "react";
import api from "../../../services/api/notifications-api";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleError } = useLogin();

  useEffect(() => {
    notificationsAPI();
  }, []);

  const notificationsAPI = async () => {
    setLoading(true);
    try {
      const response = await api.notificationsList();
      // //console.log("Response", response);
      if (response && response.data.data.length > 0) {
        setNotifications(response.data.data);
        setLoading(false);
      } else {
        // //console.log("error occuered");
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
      if (err.response.status == 401) {
        handleError();
        setLoading(false);
      }
    }
  };

  return { notifications, loading };
};
