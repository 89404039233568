import logo from "../assets/img/new-header-logo.png";
import Profileicon from "../assets/img/profile-pic.png";
import "../assets/css/newheader.css";
import {
  Outlet,
  NavLink,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Logout from "../components/logout";
import img1 from "../assets/img/profile-pic.png";
import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";
import { useContext, useState } from "react";
import { useEffect } from "react";
import RecordNotfound from "./recorderror";
import api from "../services/api/activity-api";
import NewSidebar from "./newsidebar";
import ProfileModal from "./ProfileModal";
import { UserContext } from "../App";
import DoctorImg from "../assets/img/DoctorImg.png"

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPatient, setShowPatient] = useState(false);

  const [hitApi, setHitApi] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myPatientDetails, setMyPatientDetails] = useState([]);
  const [showMObileNav, setShowMobileNav] = useState(false);
  const [show, setShow] = useState(false);

  const myPatientAPI = async (request) => {
    // //console.log("fromHeader", fromHeader)
    setLoading(true);
    try {
      const response = await api.referPatientsList(request);
      // //console.log("Response", response);
      if (response && response.data.data.patients_list.data.length !== 0) {
        setNotFound(false);
        setMyPatientDetails(response.data.data.patients_list.data);
        setLoading(false);
      } else {
        setLoading(false);
        setNotFound(true);
        // //console.log("length zero");
      }
    } catch (err) {
      //console.log(err);
    }
  };

  /*SEARCHING ON ENTER */

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  //   if (hitApi) {
  //     if (e.target.value.length === 0) {
  //       setHitApi(false);
  //       setShowPatient(false);
  //       //console.log("hiii faltu");
  //       myPatientAPI({ page: 1 });
  //     }
  //   }
  // };

  // const handleKeyDown = (e) => {
  //   if (
  //     e.key === "Enter" ||
  //     e.key === "Go" ||
  //     e.key === "Search" ||
  //     e.key === "NumpadEnter" ||
  //     e.key === "Return"
  //   ) {
  //     //console.log("search", searchTerm);
  //     if (searchTerm.length > 0) {
  //       setHitApi(true);
  //       myPatientAPI({ page: 1, keyword: searchTerm });
  //       setShowPatient(true);
  //     }
  //   }
  // };

  /* END */

  const handleNavSearch = (name) => {
    // //console.log("hii navbar", name);
    navigate(`/mypatient/${name}`);
    setShowPatient(false);
    setSearchTerm("");
  };

  /* SEARCHING USING DEBOUNCE */
  const handleKeyUp = (e) => {
    setSearchTerm(e.target.value);
    if (hitApi) {
      if (e.target.value.length === 0) {
        setHitApi(false);
        setShowPatient(false);
        // myPatientAPI({ page: 1 });
      }
    }
  };

  useEffect(() => {
    let timerId;
    if (searchTerm) {
      timerId = setTimeout(() => {
        setHitApi(true);
        myPatientAPI({ page: 1, keyword: searchTerm });
        setShowPatient(true);
      }, 500);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  /* END */

  const handleSidebar = (e) => {
    e.preventDefault();
    setShowMobileNav(true);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg NewHeader-nav">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={(e) => handleSidebar(e)}
          >
            <span
              className="navbar-toggler-icon mv__navbar_toggler_icon"
              style={{ color: "#FFFFFF !important", marginTop: "" }}
            ></span>
          </button>

          <Link className="navbar-brand header-logo" to="/dashboard">
            <div className="NewHeader_logo">
              <img
                src={logo}
                style={{ width: "100%", height: "100%" }}
                alt=""
              ></img>
            </div>
          </Link>

          <div className="search_outer_div position-relative">
            <div
              className="search_section mv__search_field"
              style={{ display: "block !important" }}
            >
              {location.pathname == "/dashboard" ||
              location.pathname == "/documents" ||
              location.pathname == "/notification" ||
              location.pathname == "/newpatient" ||
              location.pathname == "/existing-patient-post" ? (
                <>
                  <i
                    className="fa fa-search mv__fa_search"
                    aria-hidden="true"
                    style={{
                      fontSize: "15px",
                      color: "#a7a7a7",
                      position: "absolute",
                      top: "12px",
                      right: "20px",
                    }}
                  ></i>
                  <input
                    className="form-control me-2 Newheader-input"
                    type="text"
                    style={{ background: "#D7F2F6" }}
                    placeholder="Search Patient here..."
                    aria-label="Search"
                    // onChange={(e) => handleSearch(e)}
                    // onKeyDown={handleKeyDown}
                    // value={searchTerm}
                    onKeyUp={handleKeyUp}
                  />
                </>
              ) : null}

              {showPatient ? (
                <div
                  className="search_div "
                  style={{
                    width: "calc(100% - 8px) !important",
                    background: "#ffffff",
                    border: "1px solid #e4e0e0",
                    boxShadow: " 0px 14px 54px rgba(0, 0, 0, 0.1)",
                    position: "absolute",
                    borderRadius: "2px",
                    height: "auto",
                    minHeight: "70px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  {!notFound &&
                  myPatientDetails &&
                  myPatientDetails.length > 0 ? (
                    myPatientDetails.map((patient, index) => {
                      return (
                        <div
                          className="search_content_div"
                          // style={{ display: "flex", padding: "6px" }}
                          key={index}
                          onClick={(e) => handleNavSearch(patient.patient_name)}
                        >
                          <div
                            className="search_left_content"
                            // style={{
                            //   border: "1px solid #cec5c5",
                            //   borderRadius: "50%",
                            // }}
                          >
                            {/* <img src={img1} alt="" /> */}
                            <span className="mv__user_text">R</span>
                          </div>
                          <div
                            className="search_right_div"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                paddingLeft: "6px",
                                margin: "0",
                                lineHeight: "40px",
                              }}
                            >
                              {patient.patient_name}
                            </p>
                            <p
                              style={{
                                paddingLeft: "6px",
                                margin: "0",
                                lineHeight: "40px",
                              }}
                            >
                              {patient.procedure}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="search_h3">No record found</h3>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="ms-auto  pe-2">
            <ul className="navbar-nav  mb-lg-0 Newheader-ul mv__newheader_ul">
              {/* <li className="nav-item mobileview-nav-btnn ">
                <NavLink
                  to="/mypatient"
                  className="nav-link newheader-btnn newHeader-post-patient mv__btn__refer "
                >
                  Total Patients
                </NavLink>
              </li>
              <li className="nav-item mobileview-nav-btnn">
                <NavLink
                  to="/documents"
                  className="nav-link newheader-btnn newHeader-post-patient   "
                >
                  Total Documents
                </NavLink>
              </li> */}
              <li
                className="nav-item"
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div className="mobile-view-search-icon me-4">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <div className="Newheader-img-holder mv__img_holder">
                  {profile.profileImg ? (
                    <img src={profile?.profileImg} alt="" />
                  ) : (
                    <img src={DoctorImg} alt="" />
                  )}
                </div>
                <Logout handleShow={handleShow} />
              </li>
            </ul>
          </div>
          {/* </div> */}
        </nav>

        {/* <div
          className={
            location.pathname == "/mypatient"
              ? "container-fluid mobile-header-btnn mv__mobile_header_btn mv__btn_top"
              : "container-fluid mobile-header-btnn mv__mobile_header_btn"
          }
        >
          <div className="row">
            <div className="col-12 col-sm-6 mb-3 mb-sm-0 mb-md-0 mb-lg-0 ">
              <div className="mobile-design-btnn">
                <NavLink
                  to="/newpatient"
                  className="nav-link  mobile-btn mobile-view-btnn mv__btn_mode "
                >
                  Refer New Patient
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="mobile-design-btnn">
                <NavLink
                  to="/existing-patient-post"
                  className="nav-link  mobile-view-btnn mv__btn_mode"
                >
                  Existing Patient Post OP
                </NavLink>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {show ? <ProfileModal show={show} setShow={setShow} /> : ""}
    </>
  );
};

export default Header;
