import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { useContext, useEffect } from "react";
import MyPatientSidebar from "../components/my-patient-sidebar";
import NewHeader from "../components/newHeader";
import NewSidebar from "../components/newsidebar";
import PubNub from "pubnub";
import { PubNubProvider, usePubNub } from "pubnub-react";
import OneSignal from "react-onesignal";
import axios from "axios";

export const ProtectedRoute = ({ children }) => {
  const { userLoggedIn, lastRoute, setLastRoute } = useContext(UserContext);
  let location = useLocation();
  const navigate = useNavigate();

  /* Pubnub implementation */
  let userFromLocal = JSON.parse(localStorage.getItem("user"));
  // //console.log("userFromLocal", userFromLocal?.id);
  const pubnub = new PubNub({
    publishKey: "pub-c-52377dc0-a888-44a0-841f-825b319416b9",
    subscribeKey: "sub-c-c2a10f4c-394c-4b89-ba16-4de660c4f0b0",
    uuid: `${userFromLocal?.id}`,
    // uuid: "myUniqueUU",
    restore: true,
    autoNetworkDetection: true,
  });

  useEffect(() => {
    // //console.log("location", location)
    // //console.log('lastroute',lastRoute )
    setLastRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    // if(location.pathname === "/" && lastRoute){
    navigate(`${lastRoute}`);
  }, [window.location.reload]);

  useEffect(() => {
    if (userLoggedIn) {
      OneSignal.init({
        appId: "c17357fa-79b9-4707-9010-b2e13065228c",
        // appId: "fc8c5499-099a-4181-bd54-91bc3a1c7620",
        // allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        // OneSignal initialization completed
        //console.log("OneSignal initialized");
        // Subscribe to the OneSignal prompt for push notifications
        OneSignal.showNativePrompt();

        // Get OneSignal user ID and perform additional actions
        OneSignal.getUserId().then(function (userId) {
          if (userId) {
            // //console.log("usermmm", userId);
            const req = { deviceToken: userId };
            let userToken = localStorage.getItem("token");
            let token = JSON.parse(userToken);
            // axios
            //   .post(
            //     "https:dev.admin.monsonvision.com/public/api/save-web-onesignl-token",
            //     req,
            //     {
            axios
              .post(
                "https://api.monsonvision.com/public/api/save-web-onesignl-token",
                req,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                // //console.log(response.data);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
      });
    }
  }, [userLoggedIn]);

  if (!userLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      {/* {location.pathname === "/mypatient" ? (
        <MyPatientSidebar>{children}</MyPatientSidebar>
      ) : (
        <Sidebar>{children}</Sidebar>
      )} */}
      <PubNubProvider client={pubnub}>
        <NewHeader />
        <NewSidebar>{children}</NewSidebar>
      </PubNubProvider>
    </>
  );
};
export default ProtectedRoute;
