import { useEffect, useRef, useState } from "react";
import api from "../../../services/api/refer-api";
import apis from "../../../services/api/procedure-api";
import { useNavigate } from "react-router-dom";
import pdfIcon from "../../../assets/img/pdf-img.png";
import docIcon from "../../../assets/img/doc-logo.png";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useRefer = () => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);
  const [check, setCheck] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [firstProcedure, setFirstProcedure] = useState("");
  const [loading, setLoading] = useState(false);
  const [docUploadError, setDocUploadError] = useState("");

  const navigate = useNavigate();
  const hasMounted = useRef(false);
  const { handleError } = useLogin();

  useEffect(() => {
    if (!hasMounted.current) {
      proceduresAPI();
      hasMounted.current = true;
    }
  }, []);

  // useEffect(() => {
  //   //console.log("check", check);
  // }, [check]);

  // useEffect(() => {
  //   //console.log("url", previewUrls);
  // }, [previewUrls]);

  // useEffect(() => {
  //   setPreviewUrls([]);
  // }, [show]);

  const proceduresAPI = async () => {
    try {
      const response = await apis.procedure();
      // //console.log("Response", response);
      if (response && response.data) {
        setProcedures(response.data.data.procedures_list);
        setFirstProcedure(response.data.data.procedures_list[0].procedure);
      } else {
      }
    } catch (err) {
      //console.log(err);
      if (err.response.status == 401) {
        handleError();
        setLoading(false);
      }
    }
  };

  const referNewPatientAPI = async (request) => {
    // //console.log("ravy", request);
    setLoading(true);
    try {
      const response = await api.referNewPatient(request);
      // //console.log("Response", response);
      if (response && response.data.status === 1) {
        // //console.log("sucess", response.data.message);
        setMsg(response.data.message);
        setShow(true);
        setPreviewUrls([]);
        setLoading(false);
        navigate("/mypatient");
      } else {
        // //console.log("error");
        setMsg(response.data.message);
        setShow(true);
        setPreviewUrls([]);
        setLoading(false);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  // const handleFileSelect = (e, setFieldValue, document) => {
  //   const newFiles = [...document, ...e.target.files];
  //   //console.log("newFiles", newFiles)
  //   setFieldValue("documents", newFiles);

  //   const newPriviewUrls = [];
  //   for (let i = 0; i < newFiles.length; i++) {
  //     const file = newFiles[i];
  //     const objectUrl = URL.createObjectURL(file);
  //     newPriviewUrls.push(objectUrl);
  //   }
  //   setPreviewUrls(newPriviewUrls);
  // };

  const handleFileSelect = (e, setFieldValue, document) => {
    setDocUploadError("");
    const newFiles = [...document, ...e.target.files];
    // //console.log("newFiles", newFiles);
    // const acceptedFiles = newFiles.filter((file)=> file.type !== "application/pdf" && file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    if (newFiles.length > 15) {
      setDocUploadError("Only a maximum of 15 files are allowed.");
      setTimeout(() => {
        setDocUploadError("");
      }, 3000);
    } else {
      setFieldValue("documents", newFiles);

      const newPriviewUrls = [];
      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];
        if (
          file.type !== "application/pdf" &&
          file.type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
          file.type != "application/msword"
        ) {
          const objectUrl = URL.createObjectURL(file);
          newPriviewUrls.push(objectUrl);
        } else if (
          file.type ==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type == "application/msword"
        ) {
          newPriviewUrls.push(docIcon);
        } else {
          newPriviewUrls.push(pdfIcon);
        }
      }
      setPreviewUrls(newPriviewUrls);
    }
  };

  const handleDelete = (index, setFieldValue, document) => {
    const newFiles = [...document];
    newFiles.splice(index, 1);
    setFieldValue("documents", newFiles);
    // if (newFiles.length > 15) {
    //   setDocUploadError("Only a maximum of 15 files are allowed.");
    // } else {
    //   setDocUploadError("");

    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
    // }
  };

  return {
    referNewPatientAPI,
    show,
    setShow,
    msg,
    handleDelete,
    handleFileSelect,
    previewUrls,
    setPreviewUrls,
    check,
    setCheck,
    procedures,
    firstProcedure,
    loading,
    docUploadError,
  };
};
